import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class CheckinTabs extends Vue {
  @Prop({ default: "" })
  tabSelected!: string;

  @Prop({ default: () => [] })
  tabs!: string[];

  @Prop({ default: false })
  isMobile!: boolean;

  selectTab(selectedTab: string) {
    this.$emit("tabChanged", selectedTab);
  }
}
